import { Maybe } from "@/domains/common/types";
import { PusherEventKind } from "@/domains/pusher/constants";
import { api } from "@/modules/api";
import { logger } from "@/modules/logger";
import { objectModule } from "@/modules/object";
import { AppStore } from "@/store";
import { ModalDefinitionKind } from "@/store/modals/types";
import { QueryObservable } from "@/store/queries/QueryObservable";
import { FetchValue } from "@/store/queries/types";
import { AppStoreBaseSyncStore } from "@/store/sync/AppStoreBaseSyncStore";
import { AppSyncActionQueue } from "@/store/sync/AppSyncActionQueue";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import {
  ListSyncUpdatesResponse,
  SyncUpdate,
  SyncModelData,
  SyncModelKind,
  BootstrapSyncUpdatesPaginatedResponse,
  SyncModelRecomputePriority,
} from "@/store/sync/types";
import {
  generateSyncActionSpaceScopedPusherChannelKey,
  generateSyncActionSpaceAccountScopedPusherChannelKey,
} from "@/store/sync/utils";
import { AppSubStoreArgs } from "@/store/types";
import { isClientResetRequiredError, isClientUpgradeError } from "@/store/utils/errors";
import { debounce } from "lodash-es";
import {
  action,
  makeObservable,
  observable,
  override,
  runInAction,
  computed,
  onBecomeObserved,
  onBecomeUnobserved,
} from "mobx";
import { AbortError } from "p-retry";
import { Channel } from "pusher-js";
import { BackendApiResponse } from "@/modules/api/errorHandling";
import { DateTime } from "luxon";
import { LOGOUT_TIMEOUT, LOGOUT_HEARTBEAT_INTERVAL, SYNC_LOCK_ID } from "@/store/sync/constants";
import { LogoutState } from "@/components/sync/types";
import { liveQuery, Subscription } from "dexie";

const CLIENT_RESET_REQUIRED = "CLIENT_RESET_REQUIRED";

type SyncQueryValue = ListSyncUpdatesResponse;

export class AppStoreSyncStore extends AppStoreBaseSyncStore<
  AppStore,
  AppSyncActionQueue,
  ListSyncUpdatesResponse
> {
  private spaceScopedPusherChannel: Maybe<Channel>;
  private spaceAccountScopedPusherChannel: Maybe<Channel>;

  public logoutState: LogoutState | undefined;
  public logoutStateSubscription: Maybe<Subscription> = undefined;
  public isActiveLogoutTab = false;

  public stores: Record<
    SyncModelKind,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    BaseSyncModelStore<any, SyncModelData> | undefined
  > = {
    CHAT_CONVERSATION: this.store.chatConversations,
    CHAT_MESSAGE: this.store.chatMessages,
    COLLECTION: this.store.collections,
    COLLECTION_ITEM: this.store.collectionItems,
    COLLECTION_METADATA: this.store.collectionMetadata,
    CONTACT: this.store.contacts,
    FAVORITE_ITEM: this.store.favoriteItems,
    NOTE: this.store.notes,
    NOTE_CONTENT_DOCUMENT: this.store.noteContentDocuments,
    SAVED_SEARCH: this.store.savedSearches,
    SPACE_ACCOUNT_CHAT_MESSAGE: this.store.spaceAccountChatMessages,
    SPACE_ACCOUNT_COLLECTION: this.store.spaceAccountCollections,
    SPACE_ACCOUNT_CONTACT: this.store.spaceAccountContacts,
    SPACE_ACCOUNT_NOTE: this.store.spaceAccountNotes,
    SPACE_ACCOUNT_FEATURE_FLAGS_CONFIG: this.store.spaceAccountFeatureFlags,
    SPACE_ACCOUNT_TEMPLATE: this.store.spaceAccountTemplates,
    SPACE_ACCOUNT_TOPIC: this.store.spaceAccountTopics,
    SPACE_ACCOUNT_TOPIC_ITEM: this.store.spaceAccountTopicItems,
    DATA_IMPORT: this.store.dataImports,
    DATA_IMPORT_ITEM: undefined,
    DATA_EXPORT: this.store.dataExports,
    SPACE_ACCOUNT_APP_CALLOUT: this.store.spaceAccountAppCallouts,
    API_KEY: this.store.apiKeys,
    TEMPLATE: this.store.templates,
    TEMPLATE_CONTENT_DOCUMENT: this.store.templateContentDocuments,
  };

  public storeRecomputePriority: Record<SyncModelKind, number> = {
    CHAT_CONVERSATION: SyncModelRecomputePriority.Normal,
    CHAT_MESSAGE: SyncModelRecomputePriority.Normal,
    COLLECTION: SyncModelRecomputePriority.Normal,
    COLLECTION_ITEM: SyncModelRecomputePriority.Normal,
    COLLECTION_METADATA: SyncModelRecomputePriority.Normal,
    CONTACT: SyncModelRecomputePriority.Normal,
    FAVORITE_ITEM: SyncModelRecomputePriority.Normal,
    NOTE: SyncModelRecomputePriority.Normal,
    NOTE_CONTENT_DOCUMENT: SyncModelRecomputePriority.Skip, // Delayed hydration
    SAVED_SEARCH: SyncModelRecomputePriority.Normal,
    SPACE_ACCOUNT_CHAT_MESSAGE: SyncModelRecomputePriority.High,
    SPACE_ACCOUNT_COLLECTION: SyncModelRecomputePriority.High,
    SPACE_ACCOUNT_CONTACT: SyncModelRecomputePriority.High,
    SPACE_ACCOUNT_NOTE: SyncModelRecomputePriority.High,
    SPACE_ACCOUNT_FEATURE_FLAGS_CONFIG: SyncModelRecomputePriority.Normal,
    SPACE_ACCOUNT_TEMPLATE: SyncModelRecomputePriority.High,
    SPACE_ACCOUNT_TOPIC: SyncModelRecomputePriority.Normal,
    SPACE_ACCOUNT_TOPIC_ITEM: SyncModelRecomputePriority.Normal,
    TEMPLATE: SyncModelRecomputePriority.Normal,
    TEMPLATE_CONTENT_DOCUMENT: SyncModelRecomputePriority.Normal,
    DATA_IMPORT: SyncModelRecomputePriority.Normal,
    DATA_IMPORT_ITEM: SyncModelRecomputePriority.Skip, // Store does not exist
    DATA_EXPORT: SyncModelRecomputePriority.Low,
    SPACE_ACCOUNT_APP_CALLOUT: SyncModelRecomputePriority.Normal,
    API_KEY: SyncModelRecomputePriority.Normal,
  };

  constructor(injectedDeps: AppSubStoreArgs<AppStore>) {
    super(injectedDeps);
    runInAction(() => {
      this.actionQueue = this.createSyncActionQueue();
    });

    makeObservable<
      this,
      | "spaceScopedPusherChannel"
      | "spaceAccountScopedPusherChannel"
      | "bulkProcessRemote"
      | "clearPendingExternalOperations"
    >(this, {
      checkLogoutStatus: true,
      db: computed,

      logout: action,
      logoutState: observable,
      logoutStateSubscription: observable,
      isActiveLogoutTab: observable,

      stores: true,
      storeRecomputePriority: true,
      spaceScopedPusherChannel: observable,
      spaceAccountScopedPusherChannel: observable,

      createSyncActionQueue: false,
      finishProcessingQueryResponse: override,
      syncQuery: override,
      subscribe: override,
      unsubscribe: override,
      processSyncUpdate: override,
      fetchAndSaveBootstrapEvents: override,
      initialize: override,
      bulkProcessLocal: override,

      getSyncUpdates: false,
      handleApiResponseErrors: false,
      queryForSyncActions: action,
      handleClientUpgradeError: action,
      bulkProcessRemote: true,
      handleClientResetRequiredError: true,
      clearPendingExternalOperations: true,
    });

    onBecomeObserved(this, "logoutState", () => {
      this.logoutStateSubscription?.unsubscribe();
      this.logoutStateSubscription = liveQuery(this.store.memDb.settings.getLogoutState).subscribe(
        logoutState => {
          runInAction(() => {
            const newLogoutState = logoutState || LogoutState.Idle;
            logger.info({
              message: "[SYNC][AppStoreSyncStore] logoutState changed",
              info: { logoutState: newLogoutState },
            });

            this.logoutState = newLogoutState;
          });
        }
      );
    });

    onBecomeUnobserved(this, "logoutState", () => {
      this.logoutStateSubscription?.unsubscribe();
    });
  }

  createSyncActionQueue() {
    return new AppSyncActionQueue({
      getSpaceId: () => this.store.spaces.myPersonalSpaceId,
      api: this.api,
      pusher: this.pusher,
      store: this.store,
    });
  }

  get db() {
    return this.store.memDb;
  }

  get syncQuery() {
    const id = "sync-updates";

    const fetchValue: FetchValue<SyncQueryValue> = async signal => {
      if (signal.aborted) return;
      console.debug("[SYNC][AppStoreSyncStore] Querying for sync actions...");

      /**
       * This block does two things:
       * - API Call to fetch the latest sync updates
       * - Process the data (update our local DB)
       * - Persist the last sync ID (mark as being synced up to this point)
       *
       * This whole block holds a lock to ensure that only one
       * tab is fetching and processing sync updates at a time.
       *
       * To improve this, we likely want to model this implementation as
       * something closer to a "LEADER"-based approach, such as
       * - https://evilmartians.com/chronicles/cool-front-end-arts-of-local-first-storage-sync-and-conflicts#multiple-tabs
       *
       * Right now we can end up in a bit of a strange scenario where different
       * tabs perform subsequent sync requests (the locking ensures the lastSyncIds are
       * correct, but it can make it a bit more challenging to debug.)
       */
      return await navigator.locks.request(
        SYNC_LOCK_ID,
        { signal, mode: "exclusive", ifAvailable: false },
        async () => {
          const data = await this.getSyncUpdates(signal);

          if (!data) return null;

          runInAction(() => {
            this.isSyncing = true;
          });

          /**
           * One note:
           * - This write across two collections
           * - `processSyncUpdate` => db.syncUpdates
           * - `finishProcessingQueryResponse` => db.queue
           *
           * TODO - We should introduce some transaction handling to ensure that
           * we roll back if we only write "part" of a page (the protocol requires
           * that we write the entire page before updating the last sync ID).
           */
          if (data?.results?.length) {
            for (const update of data.results) {
              await this.processSyncUpdate(update);
            }

            await this.finishProcessingQueryResponse(data);
          }

          if (data?.latest_sync_id) {
            await this.saveLastSyncId(data.latest_sync_id);
          }

          runInAction(() => {
            this.isSyncing = false;
            this.lastSyncedAt = DateTime.utc();

            /**
             * We keep track of "when it completes the first sync after bootstrapping."
             * If the user hasn't connected in a while, this may take longer than usual.
             */
            this.hasCompletedInitialSync = true;
          });

          return { data };
        }
      );
    };

    const createQuery = () =>
      new QueryObservable<SyncQueryValue>({
        auth: this.store.auth,
        queriesCache: this.store.queriesCache,
        id,
        refreshInterval: this.pollingInterval,
        fetchValue,
        retries: 5,
        throttleMs: 1000,
      });

    return this.store.queriesCache.get<SyncQueryValue>(id, createQuery);
  }

  async getSyncUpdates(signal: AbortSignal): Promise<SyncQueryValue | null> {
    const spaceId = this.store.spaces.myPersonalSpaceId;

    /**
     * We restore the last sync ID from local storage before we make the API call.
     * This ensures that - if another tab made the previous request, we are ensuring
     * that we use the correct last sync ID.
     */
    await this.restoreLastSyncId();

    console.debug(`[SYNC][AppStoreSyncStore] last sync id ${this.lastSyncId}`);

    const response = await api.get("/v2/sync-updates", {
      params: {
        query: {
          last_sync_id: this.lastSyncId,
          space_id: spaceId,
        },
      },
    });

    if (signal.aborted) return null;
    this.handleApiResponseErrors(response);
    if (!response.data) return null;

    return response.data;
  }

  handleApiResponseErrors(response: BackendApiResponse) {
    if (response.error) {
      if (isClientUpgradeError(response.error)) {
        this.handleClientUpgradeError();
        throw new AbortError("CLIENT_UPGRADE_REQUIRED");
      }

      if (isClientResetRequiredError(response.error)) {
        this.handleClientResetRequiredError();
        throw new AbortError(CLIENT_RESET_REQUIRED);
      }

      throw new Error("[SYNC][AppStoreSyncStore] syncQuery error: " + response.error);
    }
  }

  async finishProcessingQueryResponse(data: ListSyncUpdatesResponse) {
    if (data?.latest_space_account_sequence_id) {
      this.latestSpaceAccountSequenceId = data.latest_space_account_sequence_id;
      await this.actionQueue.confirmSyncUpdatesUntil(data.latest_space_account_sequence_id);
    }

    if (data.has_next_page) this.queryForSyncActions();
  }

  public queryForSyncActions = debounce(async () => this.syncQuery?.forceRefetch(), 250, {
    maxWait: 1000,
  });

  public async initialize() {
    runInAction(() => {
      this.isBootstrapping = true;
    });

    try {
      await this.restoreLastSyncId();
      await this.bootstrap();
      await this.clearPendingExternalOperations();
      this.actionQueue.start();
      this.queryForSyncActions();
      this.subscribe();
      this.store.noteContentDocuments.preloadAll();
      this.store.spaceAccountTopicItems.preloadAll();
    } catch (err) {
      logger.error({
        message: "[SYNC][AppStoreSyncStore] [useEffectOnMount] AppStore failed to initialize.",
        info: { err: objectModule.safeErrorAsJson(err as Error) },
      });
      await this.clearLastSyncId();
      runInAction(() => {
        this.initializationFailed = true;
      });
    } finally {
      runInAction(() => {
        this.isBootstrapping = false;
      });
    }
  }

  subscribe() {
    if (this.spaceScopedPusherChannel && this.spaceAccountScopedPusherChannel) return;
    console.debug("[SYNC][AppStoreSyncStore] Initializing sync actions pusher subscription...");

    const spaceId = this.store.spaces.myPersonalSpaceId;
    const spaceAccountId = this.store.spaceAccounts.myPersonalSpaceAccountId;

    if (!spaceAccountId) {
      console.warn("[SYNC][AppStoreSyncStore] Skipping sync actions pusher subscription...");
      return;
    }

    const spaceScopedPusherChannelKey = generateSyncActionSpaceScopedPusherChannelKey({ spaceId });
    const spaceAccountScopedPusherChannelKey = generateSyncActionSpaceAccountScopedPusherChannelKey(
      { spaceAccountId }
    );
    this.spaceAccountScopedPusherChannel = this.pusher.subscribe(
      spaceAccountScopedPusherChannelKey
    );
    this.spaceScopedPusherChannel = this.pusher.subscribe(spaceScopedPusherChannelKey);
    this.spaceAccountScopedPusherChannel.bind(
      PusherEventKind.SYNC_UPDATE_PUBLISHED,
      this.queryForSyncActions
    );
    this.spaceScopedPusherChannel.bind(
      PusherEventKind.SYNC_UPDATE_PUBLISHED,
      this.queryForSyncActions
    );
  }

  public unsubscribe() {
    this.spaceAccountScopedPusherChannel?.unsubscribe();
    this.spaceScopedPusherChannel?.unsubscribe();
    this.spaceAccountScopedPusherChannel = undefined;
    this.spaceScopedPusherChannel = undefined;
  }

  async processSyncUpdate(update: SyncUpdate<SyncModelData>) {
    const store = this.stores[update.value.model_kind as SyncModelKind];
    if (store) await store.processSyncUpdate(update);
  }

  private async bulkProcessRemote(updates: SyncUpdate<SyncModelData>[]) {
    const kinds: SyncModelKind[] = [];

    for (const update of updates) {
      const kind = update.value.model_kind as SyncModelKind;
      const store = this.stores[kind];
      if (store?.dryProcessSyncUpdate(update) && !kinds.includes(kind)) {
        kinds.push(kind);
      }
    }

    for (const kind of kinds) {
      const store = this.stores[kind];
      if (!store) {
        logger.debug({
          message: "[SYNC][AppStoreSyncStore] Bulk processing remote: somehow we lost this store?",
          info: { store: kind },
        });

        continue;
      }

      logger.debug({
        message: "[SYNC][AppStoreSyncStore] Bulk processing remote updates for store...",
        info: { store: store.modelKind },
      });

      await store.flushRemote();
    }
  }

  private async clearPendingExternalOperations() {
    await this.actionQueue.clearPendingExternalOperations();
  }

  async fetchAndSaveBootstrapEvents() {
    let syncId: string | undefined = undefined;
    let endCursor: string | null;
    let count = 0;

    try {
      const response: BootstrapSyncUpdatesPaginatedResponse = await api.post(
        `/v2/sync-updates/bootstrap/diff`,
        {
          params: {
            query: {
              space_id: this.store.spaces.myPersonalSpaceId,
              first: null,
              exclude_sync_model_kinds: [
                SyncModelKind.NoteContentDocument,
                SyncModelKind.SpaceAccountTopicItem,
              ],
            },
          },
        }
      );

      if (response.error) {
        if (isClientUpgradeError(response.error)) {
          this.handleClientUpgradeError();
        }

        logger.warn({
          message: "[SYNC][AppStoreSyncStore] Error bootstrapping",
          info: objectModule.safeAsJson({ e: response.error }),
        });
        return { lastSyncId: undefined, count };
      }

      const results = response.data?.results || [];
      count += results.length;
      await this.bulkProcessRemote(results);
      syncId = response.data?.sync_id;
      endCursor = response.data?.end_cursor ?? null;

      if (syncId == null) {
        logger.warn({
          message: "[SYNC][AppStoreSyncStore] Error bootstrapping: No sync id",
        });
        return { lastSyncId: undefined, count };
      }

      while (endCursor != null) {
        const response: BootstrapSyncUpdatesPaginatedResponse = await api.get(
          "/v2/sync-updates/bootstrap/diff/{sync_id}",
          {
            params: {
              path: {
                sync_id: syncId,
              },
              query: {
                first: null,
                after: endCursor,
                exclude_sync_model_kinds: [
                  SyncModelKind.NoteContentDocument,
                  SyncModelKind.SpaceAccountTopicItem,
                ],
              },
            },
          }
        );

        const results = response.data?.results || [];
        count += results.length;
        await this.bulkProcessRemote(results);
        endCursor = response.data?.end_cursor ?? null;
      }
    } catch (e) {
      logger.warn({
        message: "[SYNC][AppStoreSyncStore] Error bootstrapping",
        info: objectModule.safeAsJson({ e }),
      });
      return { lastSyncId: undefined, count };
    }

    return { lastSyncId: syncId, count };
  }

  async bulkProcessLocal() {
    const prioritizedStores = Object.entries(this.storeRecomputePriority)
      .filter(([_, priority]) => priority !== SyncModelRecomputePriority.Skip)
      .sort(([, a], [, b]) => a - b)
      .map(([kind]) => kind as SyncModelKind);
    for (const kind of prioritizedStores) {
      const store = this.stores[kind as SyncModelKind];
      if (store) {
        logger.debug({
          message: "[SYNC][AppStoreSyncStore] Bulk recomputing store",
          info: { store: store.modelKind },
        });
        await store.bulkRecomputeAll();
      }
    }
  }

  handleClientUpgradeError() {
    this.store.modals.addModal({
      kind: ModalDefinitionKind.SyncError,
      syncError: {
        title: "App update required",
        message:
          "In order to continue using Mem, you need to update to the latest version of the app.",
        resetActionLabel: "Update",
        modalActionHandler: async () => {
          await this.store.forceUpgradeClient();
        },
      },
    });
  }

  handleClientResetRequiredError() {
    this.store.modals.addModal({
      kind: ModalDefinitionKind.SyncError,
      syncError: {
        title: "App reload required",
        message:
          "In order to continue syncing changes to your Mem, you'll need to reload the app. This is more likely to happen if you're using Mem from multiple browser tabs at the same time.",
        resetActionLabel: "Reload",
        modalActionHandler: async () => {
          await this.store.resetStorageAndReload();
        },
      },
    });
  }

  async logout() {
    const beforeUnload = async (e: BeforeUnloadEvent) => {
      e.preventDefault();
      const logoutState = await this.store.memDb.settings.getLogoutState();
      if (logoutState !== LogoutState.LoggingOut) return;
      return "Logging out. Please wait...";
    };

    const logoutState = await this.store.memDb.settings.getLogoutState();
    if (logoutState === LogoutState.LoggedOut) return;

    await navigator.locks.request(
      SYNC_LOCK_ID,
      { mode: "exclusive", ifAvailable: false },
      async () => {
        logger.info({ message: "[SYNC][AppStoreSyncStore] Starting log out..." });

        await this.store.memDb.settings.setLogoutState(LogoutState.LoggingOut);
        runInAction(() => (this.isActiveLogoutTab = true));
        window.addEventListener("beforeunload", beforeUnload);

        // Setup
        const interval = setInterval(() => {
          this.store.memDb.settings.setLogoutHeartbeat();
        }, LOGOUT_HEARTBEAT_INTERVAL);

        // Do the main logout cleanup
        logger.info({ message: "[SYNC][AppStoreSyncStore] Cleaning up database..." });
        await this.db.erase();
        logger.info({ message: "[SYNC][AppStoreSyncStore] Database cleaned up" });

        // Cleanup
        clearInterval(interval);
        window.removeEventListener("beforeunload", beforeUnload);
        runInAction(() => (this.isActiveLogoutTab = false));
        await this.store.memDb.settings.setLogoutState(LogoutState.LoggedOut);
        logger.info({ message: "[SYNC][AppStoreSyncStore] Logged out" });
      }
    );
  }

  async checkLogoutStatus() {
    if (this.isActiveLogoutTab) return;
    logger.info({
      message: `[SYNC][AppStoreSyncStore] checkLogoutStatus`,
      info: {
        isActiveLogoutTab: this.isActiveLogoutTab,
        logoutState: this.logoutState || "undefined",
      },
    });

    // Its possible heartbeat was not set (page crushed before it could be set)
    const heartbeat = await this.store.memDb.settings.getLogoutHeartbeat();
    if (!heartbeat) {
      await this.logout();
      return;
    }

    const now = Date.now();
    const diff = now - heartbeat;

    if (diff > LOGOUT_TIMEOUT) await this.logout();
  }
}
