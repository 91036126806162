import { moduleFactory } from "@/factories/module";

import { askForUserFile } from "@/modules/file/askForUserFile";
import { uploadFile } from "@/modules/file/upload";
import { convertEncodedFileContentToBlob } from "@/modules/file/blob";
import {
  downloadFileFromContent,
  downloadFileFromUrlDirectly,
  downloadFileFromUrl,
} from "@/modules/file/download";
import { checkIfFileCanBeUploaded, checkIfImageCanBeUploaded } from "@/modules/file/validation";

export const fileModule = moduleFactory.create({
  upload: uploadFile,
  downloadFileFromContent: downloadFileFromContent,
  downloadFileFromUrl: downloadFileFromUrl,
  downloadFileFromUrlDirectly: downloadFileFromUrlDirectly,
  convertEncodedFileContentToBlob: convertEncodedFileContentToBlob,
  checkIfFileCanBeUploaded: checkIfFileCanBeUploaded,
  checkIfImageCanBeUploaded: checkIfImageCanBeUploaded,
  askForUserFile,
});
