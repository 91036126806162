import { logger } from "@/modules/logger";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import { action, makeObservable, observable, runInAction } from "mobx";

/**
 * The TabLifecycleManager tracks the current lifecycle state of the browser tab.
 * It listens for visibility and focus changes to determine if the tab is active,
 * passive, hidden, frozen, or terminated.
 *
 * This helps optimize performance and resource usage by adjusting behavior based
 * on whether the tab is actively being used or in the background.
 *
 * Details: https://developer.chrome.com/docs/web-platform/page-lifecycle-api
 */
export enum TabLifecycleState {
  /**
   * A page is in the active state if it is visible and has input focus.
   */
  Active = "active",

  /**
   * A page is in the passive state if it is visible and does not have input focus.
   */
  Passive = "passive",

  /**
   * A page is in the hidden state if it is not visible (and has not been frozen, discarded, or terminated).
   */
  Hidden = "hidden",

  /**
   * In the frozen state the browser suspends execution of freezable tasks
   * in the page's task queues until the page is unfrozen.
   *
   * This means things like JavaScript timers and fetch callbacks don't run.
   * Already-running tasks can finish (most importantly the freeze callback),
   * but they may be limited in what they can do and how long they can run.
   *
   * Browsers freeze pages as a way to preserve CPU/battery/data usage;
   * they also do it as a way to enable faster back/forward navigation —
   * avoiding the need for a full page reload.
   *
   * Note: Frozen only supported by Chrome > 68 atm
   */
  Frozen = "frozen",

  /**
   * A page is in the terminated state once it has started being unloaded
   * and cleared from memory by the browser.
   *
   * No new tasks can start in this state,
   * and in-progress tasks may be killed if they run too long.
   */
  Terminated = "terminated",
}

export class TabLifecycleManager extends AppSubStore {
  public _state: TabLifecycleState;
  private readonly opts = { capture: true };

  constructor(injectedDeps: AppSubStoreArgs) {
    super(injectedDeps);

    this._state = this.determineState();
    this.initializeEventListeners();

    makeObservable<
      TabLifecycleManager,
      | "opts"
      | "state"
      | "determineState"
      | "logStateChange"
      | "initializeEventListeners"
      | "_state"
      | "setState"
    >(this, {
      opts: false,
      state: false,
      determineState: false,
      logStateChange: false,
      initializeEventListeners: false,
      _state: observable,
      setState: action,
    });
  }

  get state() {
    return this._state;
  }

  private setState(nextState: TabLifecycleState) {
    runInAction(() => {
      this._state = nextState;
    });
  }

  private determineState(): TabLifecycleState {
    if (document.visibilityState === "hidden") {
      return TabLifecycleState.Hidden;
    }

    if (document.hasFocus()) {
      return TabLifecycleState.Active;
    }

    return TabLifecycleState.Passive;
  }

  private logStateChange(nextState: TabLifecycleState): void {
    const prevState = this._state;
    if (nextState !== prevState) {
      logger.debug({
        message: "[TabLifecycleManager] State change",
        info: { prevState, nextState },
      });

      this.setState(nextState);
    }
  }

  private initializeEventListeners(): void {
    // Standard lifecycle events
    ["pageshow", "focus", "blur", "visibilitychange", "resume"].forEach(type => {
      window.addEventListener(type, () => this.logStateChange(this.determineState()), this.opts);
    });

    // Freeze event
    window.addEventListener(
      "freeze",
      () => {
        this.logStateChange(TabLifecycleState.Frozen);
      },
      this.opts
    );

    // Pagehide event
    window.addEventListener(
      "pagehide",
      event => {
        this.logStateChange(
          event.persisted ? TabLifecycleState.Frozen : TabLifecycleState.Terminated
        );
      },
      this.opts
    );
  }
}
