import { observer } from "mobx-react-lite";
import { useAppStore } from "@/store";
import { CollectionViewPageListEmpty } from "@/pages/collections-view/components/CollectionsViewPageListEmpty";
import { MdsVirtualizedItemList } from "@/design-system/components/item-list";
import { useEffect } from "react";

interface Props {
  isSidePanel: boolean;
}

export const CollectionsViewPageList = observer<Props>(({ isSidePanel }) => {
  const { pageStore, store } = useAppStore();
  const page = isSidePanel ? store.sidePanel.collectionsViewPage : pageStore.collectionsViewPage;
  const collection = page.collectionObservable;

  page.listState.useListStateEffects();

  useEffect(() => {
    const addToRecents = async () => {
      if (!collection) return;
      await collection.addToRecents();
    };

    addToRecents();
  }, [collection]);

  if (!collection) return null;
  if (page.hasNoItems) {
    return <CollectionViewPageListEmpty isSidePanel={isSidePanel} />;
  }

  return (
    <>
      <MdsVirtualizedItemList
        rows={page.itemRows}
        listState={page.listState}
        loading={page.isLoading}
      />
      {page.listState.multiSelectUi}
    </>
  );
});
