import { MdsButtonVariant } from "@/design-system/components/button";
import { MdsButton } from "@/design-system/components/button/MdsButton";
import { MdsSpacer } from "@/design-system/components/spacer";
import { MdsText } from "@/design-system/components/text";
import { MdsTextStylingMode } from "@/design-system/components/text/types";
import { EventContext } from "@/domains/metrics/context";
import { UNTITLED_NOTE_TITLE } from "@/domains/untitled/untitled";
import { uuidModule } from "@/modules/uuid";
import { resolveCollectionItemSyncModelUuid } from "@/modules/uuid/sync-models/resolveCollectionItemSyncModelUuid";
import { CollectionItemUpsertedSyncUpdateValue } from "@/store/collection-items/types";
import { CollectionItemModelData } from "@/store/collection-items/types";
import { AppStore, GuestAppStore } from "@/store";
import { getMentionedNoteIds } from "@/store/note/metadata";
import { NoteMediaKind, NoteModelData, NoteUpsertedSyncUpdateValue } from "@/store/note/types";
import {
  BaseSyncOperation,
  BaseSyncOperationParams,
} from "@/store/sync/operations/BaseSyncOperation";
import { SyncErrorHandlingType } from "@/store/sync/operations/errors/SyncError";
import { generateDefaultOwnerScopes } from "@/store/sync/operations/helpers/common";
import { AbstractSyncErrorModalFieldsGenerator } from "@/store/sync/operations/errors/SyncErrorModalFields";
import { IUpdateNoteContentUsingTemplateAndDiffOperation } from "@/store/sync/operations/types";
import {
  SyncCustomErrorData,
  SyncModelData,
  OptimisticSyncUpdate,
  SyncOperationKind,
} from "@/store/sync/types";
import { ExtractedNoteMention } from "@mem-labs/common-editor";
import { uniq } from "lodash-es";
import { runInAction } from "mobx";
import { JsonValue } from "type-fest";
import { generateTemplateSharingText } from "@/domains/templates/generateTemplateSharingText";
import { TemplateObservable } from "@/store/templates/TemplateObservable";
import { MarkTemplateUsedOperation } from "@/store/sync/operations/templates/MarkTemplateUsedOperation";
import { resolveSpaceAccountTemplateSyncModelUuid } from "@/modules/uuid/sync-models/resolveSpaceAccountTemplateSyncModelUuid";
import { resolveNoteContentDocumentSyncModelUuid } from "@/modules/uuid/sync-models/resolveNoteContentDocumentSyncModelUuid";
export class UpdateNoteContentUsingTemplateAndDiffOperation extends BaseSyncOperation<IUpdateNoteContentUsingTemplateAndDiffOperation> {
  primaryLabel: string;
  protected secondaryLabel: string;
  protected mediaKinds?: NoteMediaKind[];
  protected transactionMetadata?: Record<string, JsonValue>;
  protected tiptapJsonContent?: string;
  protected mentions?: ExtractedNoteMention[];

  template?: TemplateObservable;
  successToastMessageComponent: JSX.Element | null = null;

  get operationKind(): SyncOperationKind {
    return "UPDATE_NOTE_CONTENT_USING_TEMPLATE_AND_DIFF";
  }

  constructor({
    primaryLabel,
    secondaryLabel,
    mediaKinds,
    transactionMetadata,
    tiptapJsonContent,
    mentions,
    ...params
  }: {
    primaryLabel: string;
    secondaryLabel: string;
    mediaKinds?: NoteMediaKind[];
    transactionMetadata?: Record<string, JsonValue>;
    tiptapJsonContent?: string;
    mentions?: ExtractedNoteMention[];
  } & BaseSyncOperationParams<IUpdateNoteContentUsingTemplateAndDiffOperation>) {
    super(params);
    this.primaryLabel = primaryLabel;
    this.secondaryLabel = secondaryLabel;
    this.mediaKinds = mediaKinds;
    this.transactionMetadata = transactionMetadata;
    this.tiptapJsonContent = tiptapJsonContent;
    this.mentions = mentions;
  }

  async generateOptimisticUpdates(): Promise<OptimisticSyncUpdate<SyncModelData>[]> {
    // Apply optimistic updates regardless of whether or not there is a contentDiff
    const note = this.store.notes.get(this.payload.note_id);
    if (!note) return [];

    const value: NoteUpsertedSyncUpdateValue = {
      model_id: this.payload.note_id,
      model_kind: "NOTE",
      model_version: note.modelVersion,
      model_data: {
        ...note.modelData,
        primary_label: this.primaryLabel,
        secondary_label: this.secondaryLabel,
        media_kinds: this.mediaKinds || [],
        mentioned_note_ids: getMentionedNoteIds(this.mentions || []),
        locally_modified_at: this.committedAt,
        modified_by_space_account_ids: uniq([
          ...note.modelData.modified_by_space_account_ids,
          this.store instanceof GuestAppStore
            ? this.store.guestAccount.myPersonalSpaceAccountId
            : this.store.spaceAccounts.myPersonalSpaceAccountId,
        ]),
      },
      model_scopes: note.modelScopes,
    };
    const syncUpdate: OptimisticSyncUpdate<NoteModelData> = {
      optimistic_update_id: uuidModule.generate(),

      locally_committed_at: this.committedAt,
      kind: "UPSERTED",
      value,
    };

    const syncUpdates: OptimisticSyncUpdate<SyncModelData>[] = [syncUpdate];

    const template = await this.store.templates.getAsync(this.payload.template_id);

    const spaceAccountTemplateUpdates = await new MarkTemplateUsedOperation({
      store: this.store,
      payload: { template_id: this.payload.template_id },
      operationId: this.operationId,
    }).generateOptimisticUpdates();
    syncUpdates.push(...spaceAccountTemplateUpdates);

    if (!template || !template.isAutoSharingEnabled) return syncUpdates;

    // If auto-sharing is enabled, we need to add the note to the collections
    // Other ACL updates are not handled optimistically
    const collection_item_updates = template.modelData.target_scopes
      .filter(scope => scope.scope_kind === "COLLECTION_SCOPE")
      .map(scope => {
        const collectionItemId = resolveCollectionItemSyncModelUuid({
          collectionId: scope.value.collection_id,
          itemId: this.payload.note_id,
        });
        const collectionItemValue: CollectionItemUpsertedSyncUpdateValue = {
          model_id: collectionItemId,
          model_kind: "COLLECTION_ITEM",
          model_version: 0,
          model_data: {
            collection_id: scope.value.collection_id,
            item_id: this.payload.note_id,
            item_kind: "NOTE",
            locally_created_at: this.committedAt,
            added_by_space_account_id: this.store.spaceAccounts.myPersonalSpaceAccountId,
          },
          model_scopes: [generateDefaultOwnerScopes({ store: this.store })],
        };
        const collectionItemUpdate: OptimisticSyncUpdate<CollectionItemModelData> = {
          optimistic_update_id: uuidModule.generate(),
          locally_committed_at: this.committedAt,
          kind: "UPSERTED",
          value: collectionItemValue,
        };
        return collectionItemUpdate;
      });
    syncUpdates.push(...collection_item_updates);

    return syncUpdates;
  }

  async execute(): Promise<void> {
    this.template = await this.store.templates.getAsync(this.payload.template_id);
    await this.generateSuccessToastMessageComponent();

    const syncOperation = this.generateSyncOperation();
    if (!syncOperation) return;
    await this.store.sync.actionQueue.push(this);

    const optimisticUpdates = await this.generateOptimisticUpdates();
    for (const optimisticUpdate of optimisticUpdates) {
      await this.store.sync.actionQueue.applyOptimisticUpdate(this.id, optimisticUpdate);
    }

    await this.store.sync.actionQueue.clearFakeSyncUpdates(this.payload.note_id);
    // clearFakeSyncUpdates already recomputes the note.
    await this.triggerRecomputeOfNoteContentAndTemplateAndCollections();

    this.handleSuccess();
  }

  async triggerRecompute() {
    if (this.store instanceof GuestAppStore) return;

    await this.store.notes.recompute(this.payload.note_id);
    await this.triggerRecomputeOfNoteContentAndTemplateAndCollections();
  }

  async triggerRecomputeOfNoteContentAndTemplateAndCollections() {
    const noteContentDocumentId = resolveNoteContentDocumentSyncModelUuid({
      noteId: this.payload.note_id,
    });
    await this.store.noteContentDocuments.recompute(noteContentDocumentId);

    const spaceAccountTemplateId = resolveSpaceAccountTemplateSyncModelUuid({
      spaceAccountId: this.store.spaceAccounts.myPersonalSpaceAccountId,
      templateId: this.payload.template_id,
    });
    await this.store.spaceAccountTemplates.recompute(spaceAccountTemplateId);

    const template = await this.store.templates.getAsync(this.payload.template_id);
    if (template && template.isAutoSharingEnabled) {
      for (const scope of template.modelData.target_scopes) {
        if (scope.scope_kind === "COLLECTION_SCOPE") {
          const collectionItemId = resolveCollectionItemSyncModelUuid({
            collectionId: scope.value.collection_id,
            itemId: this.payload.note_id,
          });
          await this.store.collections.recompute(scope.value.collection_id);
          await this.store.collectionItems.recompute(collectionItemId);
        }
      }
    }
  }

  get successToastMessage() {
    return this.successToastMessageComponent;
  }

  async generateSuccessToastMessageComponent() {
    if (!this.template) return;
    this.successToastMessageComponent = await generateTemplateSharingText({
      template: this.template,
      store: this.store,
    });
  }

  handleInvalidError(_errorData: SyncCustomErrorData) {
    this.triggerModal(
      this.getSyncErrorModalFieldsGenerator("INVALID", {
        requestAccessAndSaveEditsInNewNoteButtonsEnabled: true,
      })
    );
  }

  handlePermissionDeniedError(_errorData: SyncCustomErrorData) {
    this.triggerModal(
      this.getSyncErrorModalFieldsGenerator("PERMISSION_DENIED", {
        requestAccessAndSaveEditsInNewNoteButtonsEnabled: true,
      })
    );
  }

  handleUnknownError(_errorData: SyncCustomErrorData) {
    this.triggerModal(
      this.getSyncErrorModalFieldsGenerator("UNKNOWN", {
        requestAccessAndSaveEditsInNewNoteButtonsEnabled: true,
      }),
      SyncErrorHandlingType.RetryWithLimit
    );
  }

  get syncErrorModalFieldsGenerator() {
    return this.getSyncErrorModalFieldsGenerator("UNKNOWN");
  }

  getSyncErrorModalFieldsGenerator(
    kind: SyncCustomErrorData["kind"],
    opts: UpdateNoteContentUsingTemplateAndDiffSyncErrorModalFieldsGeneratorOps = {
      requestAccessAndSaveEditsInNewNoteButtonsEnabled: false,
    }
  ) {
    return (store: AppStore) =>
      new UpdateNoteContentUsingDiffSyncErrorModalFieldsGenerator(
        store,
        this.id,
        this.payload.note_id,
        kind,
        opts
      );
  }
}

interface UpdateNoteContentUsingTemplateAndDiffSyncErrorModalFieldsGeneratorOps {
  requestAccessAndSaveEditsInNewNoteButtonsEnabled: boolean;
  onAfterClose?: () => void;
}

class UpdateNoteContentUsingDiffSyncErrorModalFieldsGenerator extends AbstractSyncErrorModalFieldsGenerator {
  protected id: string;
  protected noteId: string;
  protected kind: SyncCustomErrorData["kind"];
  protected requestAccessAndSaveEditsInNewNoteButtonsEnabled: boolean;
  protected onAfterClose?: () => void;

  constructor(
    store: AppStore,
    id: string,
    noteId: string,
    kind: SyncCustomErrorData["kind"],
    opts: UpdateNoteContentUsingTemplateAndDiffSyncErrorModalFieldsGeneratorOps
  ) {
    super(store);

    this.id = id;
    this.noteId = noteId;
    this.kind = kind;
    this.requestAccessAndSaveEditsInNewNoteButtonsEnabled =
      opts.requestAccessAndSaveEditsInNewNoteButtonsEnabled;
    this.onAfterClose = opts.onAfterClose;
  }

  reloadEditors() {
    // Any open editor or note viewer will be notified to reload the note without reverted operations.
    this.store.publicAppStore.interface.notifyReloadRequired(this.noteId);
    this.onAfterClose?.();
  }

  requestEditAccess = () => {
    // TODO: https://linear.app/mem-labs/issue/MEM-7702/update-note-contents-permission-denied-request-edit-access
  };

  discardEdits = () => {
    runInAction(async () => {
      await this.store.sync.actionQueue.skipAndRevertRelatedOperationsById(this.id);
      this.reloadEditors();
      this.store.sync.actionQueue.resume();
    });
  };

  saveChangesAsNewNote = async () => {
    // TODO: https://linear.app/mem-labs/issue/MEM-7703/update-note-contents-permission-denied-save-edits-as-new-note
    const note = await this.store.notes.getAsync(this.noteId);
    if (!note) return;
    await note.saveAsNewNote({ eventContext: EventContext.ErrorModal });
    this.discardEdits();
  };

  tryAgain = () => {
    this.store.sync.actionQueue.resume();
  };

  get title() {
    const note = this.store.notes.get(this.noteId);
    const title = note?.title || UNTITLED_NOTE_TITLE;
    return `Having trouble saving your “${title}”`;
  }

  get message() {
    switch (this.kind) {
      case "INVALID":
      case "UNKNOWN": {
        return `Trying again usually solves this issue. If this issue continues to appear, you can save your changes as a new note instead.`;
      }
      case "PERMISSION_DENIED": {
        return (
          <>
            You no longer have edit access to this note and your changes could not be saved. Contact
            the note owner, or{" "}
            <MdsText
              stylingMode={MdsTextStylingMode.InheritStyles}
              onClick={this.requestEditAccess}
            >
              request edit access
            </MdsText>
            , then try again.
          </>
        );
      }
    }
  }

  get resetActionLabel() {
    return this.requestAccessAndSaveEditsInNewNoteButtonsEnabled ? null : "Got it";
  }

  get extraActionButtons() {
    if (!this.requestAccessAndSaveEditsInNewNoteButtonsEnabled) return;

    return (
      <>
        <MdsButton
          label="Discard edits"
          variant={MdsButtonVariant.Outlined}
          onClick={this.discardEdits}
        />
        <MdsSpacer />
        <MdsButton
          label="Save changes as new note"
          variant={MdsButtonVariant.Outlined}
          onClick={this.saveChangesAsNewNote}
        />
        <MdsButton
          label="Try again"
          variant={MdsButtonVariant.FilledDark}
          onClick={this.tryAgain}
        />
      </>
    );
  }

  modalActionHandler = async () => {
    await this.store.sync.actionQueue.skipAndRevertRelatedOperationsById(this.id);
    this.reloadEditors();
  };
}
